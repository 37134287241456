<template>
  <div class="mt-1">
    <b-row>
      <b-col align="left">
        <b-button-group
          v-b-toggle:collapse-1
          class="my-2 filter-button-group"
          :title="getDefaultFilterMessage()"
        >
          <span class="when-open">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          <span class="when-closed">
            <b-icon icon="funnel" font-scale="1.5"></b-icon>
          </span>
          Filtra
        </b-button-group>
      </b-col>
      <b-col align="right">
        <b-button
          class="btn-create"
          type="button"
          variant="primary"
          size="sm"
          @click="onAddBookEntry"
          title="Crea"
          v-if="canVerb(resource, 'store')"
          ><b-icon icon="plus-circle" aria-hidden="true"></b-icon>
          Crea</b-button
        >
      </b-col>
    </b-row>

    <b-collapse visible id="collapse-1">
      <b-form @submit.prevent="onSearch(filterName)">
        <b-card class="filter">
          <b-row>
            <div class="form-group col-md-3">
              <div>
                <b-button-toolbar>
                  <b-button-group>
                    <base-input
                      name="Anagrafica"
                      label="Anagrafica"
                      v-model="form.inpt_label_registry"
                      :readonly="true"
                    />
                    <b-button
                      class="mt-4 btn-quick"
                      size="sm"
                      text="Button"
                      variant="lisaweb"
                      title="Ricerca Veloce"
                      @click="openQuickSearchRegistry"
                      ><b-icon icon="search"></b-icon
                    ></b-button>
                    <b-button
                      v-if="form.inpt_label_registry"
                      class="mt-4 btn-quick ml-1"
                      size="sm"
                      text="Button"
                      variant="lisaweb"
                      @click="resetRegistryId"
                      title="Elimina Anagrafica"
                      ><b-icon icon="trash"></b-icon
                    ></b-button>
                  </b-button-group>
                </b-button-toolbar>
              </div>
            </div>
            <quick-search-registry-modal
              ref="quickSearchRegistry"
              @input="handleRegistryModalInput"
            >
            </quick-search-registry-modal>
            <div class="form-group col-md-3">
              <span
                v-if="registry_data"
                class="info"
                v-html="toInfoData(registry_data, 'registry')"
              >
              </span>
            </div>
          </b-row>
          <b-row>
            <div class="col-md-3">
              <base-select
                name="salesman_id"
                label="Produttori"
                :options="salesmen"
                v-model="filter.byBroker.id"
                :taggable="salesmen_taggable"
                :multiple="true"
                :closeOnSelect="false"
                :defaultHtmlMultipleSelect="true"
                @changeTaggable="changeTaggable"
              />
            </div>
            <div class="col-md-3">
              <base-select
                name="insurer_id"
                label="Compagnia"
                :options="companies"
                v-model="filter.byInsurer.id"
              />
            </div>
            <div class="col-md-3">
              <base-datepicker
                name="Da"
                label="Da"
                v-model="filter.byCalendar.from"
              />
            </div>
            <div class="col-md-3">
              <base-datepicker
                name="A"
                label="A"
                v-model="filter.byCalendar.to"
              />
            </div>
            <div class="col-md-3">
              <base-select
                name="sectors_id"
                label="Comparti"
                :options="sectors"
                v-model="filter.bySector.id"
                :taggable="false"
                :multiple="true"
                :closeOnSelect="false"
              />
            </div>
            <div class="col-md-3">
              <base-select
                name="various_accounting"
                label="Codice Causale"
                :options="various_accounting_not_system_options"
                v-model="filter.byVariousAccounting.id"
                :taggable="false"
                :multiple="true"
                :closeOnSelect="false"
              />
            </div>
            <div class="col-md-3">
              <base-input
                name="tile"
                label="Descrizione"
                v-model="filter.byCustom.title.value"
                placeholder="Inserisci una descrizione"
              />
            </div>
          </b-row>
          <b-row>
            <div class="form-group col-md-3 align-self-end">
              <b-button
                type="submit"
                variant="lisaweb"
                size="sm"
                v-b-toggle:collapse-1
                >Cerca</b-button
              >
              <b-button
                class="btn-reset"
                type="button"
                variant="lisaweb"
                size="sm"
                @click="onClearFilter(filterName)"
                >Reset</b-button
              >
            </div>
          </b-row>
        </b-card>
      </b-form>
    </b-collapse>

    <export-options
      :exportUrl="exportUrl"
      :repository="repository"
      :resource="resource"
      :tableRef="$refs[tableRef]"
      :filter="filter"
      :options="[
        {
          code: 'LISTVAR',
          label: null,
          formats: ['csv', 'pdf'],
        },
      ]"
    ></export-options>
    <book-entries-various-accounting
      @edit="onEdit"
      @destroy="onDestroy"
      :fields="fields"
      :repository="repository"
      :resource="resource"
      :filterOn="{
        byRelations: [
          'byRegistry',
          'byBroker',
          'byVariousAccounting',
          'byTask',
          'byDocument',
        ],
      }"
      :onlyActions="['edit', 'details', 'infomodal', 'destroy']"
      :filterName="filterName"
      :ref="tableRef"
      sortField="book_date"
      descending
      :hasChecksButtonGroup="false"
    ></book-entries-various-accounting>
  </div>
</template>

<script>
import QuickSearchRegistryModal from "@/components/modals/quickSearchRegistry";
import BookEntriesVariousAccounting from "@/components/tables/BookEntriesVariousAccounting";
import BaseInput from "@/components/form/BaseInput";
import BaseSelect from "@/components/form/BaseSelect";
import BaseDatepicker from "@/components/form/BaseDatepicker";
import CustomFiltersMixin from "@/mixins/CustomFiltersMixin";
import StorageGetterMixin from "@/mixins/StorageGetterMixin";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";
import { toInfoData /* , toRelationString */ } from "@/utils/transforms";
import { toLocaleDate } from "@/utils/dates";
import { mapGetters, mapActions } from "vuex";
import ExportOptions from "@/components/ExportOptions";

export default {
  mixins: [CustomFiltersMixin, StorageGetterMixin],
  data() {
    return {
      exportUrl: null,
      filter: this.initFilter(),
      filterName: "filterVariousAccounting",
      resource: "book_entries",
      repository: "book_entry",
      tableRef: "VariousAccountingTableRef",
      notes: [],
      documents: [],
      companies: [],
      salesmen: [],
      salesmen_taggable: true,
      sectors: [],
      various_accounting_not_system_options: [],
      payment_references_opt: [],
      registry_data: null,
      fields: [
        {
          key: "book_date",
          label: this.getDictionary("book_date", "book_entry"),
          sortable: true,
          sortKey: "book_date",
          formatter: (value) => toLocaleDate(value),
        },
        {
          key: "various_accounting_code",
          label: this.getDictionary("code", "various_accounting"),
          sortable: true,
          sortKey: "byVariousAccounting.display",
        },
        {
          key: "various_accountings",
          label: this.getDictionary("various_accountings", "book_entry"),
          sortable: false,
        },
        {
          key: "title",
          label: this.getDictionary("title", "various_accounting"),
          sortable: true,
          sortKey: "title",
        },
        {
          key: "note_counter",
          label: this.getDictionary("note"),
        },
        {
          key: "document_counter",
          label: this.getDictionary("document"),
        },
      ],
      form: {
        inpt_label_registry: "",
        quick_value: "",
      },
    };
  },
  components: {
    BaseInput,
    BaseSelect,
    BaseDatepicker,
    BookEntriesVariousAccounting,
    QuickSearchRegistryModal,
    ExportOptions,
  },
  methods: {
    toInfoData,
    changeTaggable(val) {
      this.salesmen_taggable = val;
    },
    initFilter() {
      let init = {
        byView: "various_accounting",
        byInsurer: {
          id: null,
        },
        byTreasury: {
          id: null,
        },
        byRegistry: {
          id: null,
        },
        byBroker: {
          id: null,
        },
        bySector: {
          id: null,
        },
        byCustom: {
          title: {
            value: null,
            likewise: 1,
            filter: "byAttribute",
          },
        },
        byCalendar: {
          from: null,
          to: null,
        },
        byVariousAccounting: {
          id: [],
        },
      };
      return init;
    },
    onAddBookEntry() {
      this.$router.push({ name: `book_entries.create`, params: { mode: "V" } });
    },
    onAdd() {
      this.$router.push({ name: `${this.resource}.create` });
    },
    customFilterName(key, prefix = "byAttribute") {
      return `${prefix}.${key}`;
    },
    onEdit(id) {
      this.$router.push({
        name: `${this.resource}.edit`,
        params: { id: `${id}`, mode: "V" },
      });
    },
    onDestroy(id) {
      const Repo = RepositoryFactory.get(this.repository);
      Repo.destroy(id)
        .then(() => {
          this.$refs[this.tableRef].fetch();
          this.$showSnackbar({
            preset: "success",
            text: "Movimento Vario Eliminato con successo",
          });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
        });
    },
    onSearch(name) {
      let criteria = this.filter;
      this.saveFilterByName({ name, criteria });

      this.exportUrl = this.$refs[this.tableRef].loadFilter(
        this.$refs[this.tableRef].filterName
      );
      console.log(this.exportUrl);
      // quicksearch
      criteria = {};
      ["quickSearchRegistry"].forEach((element) => {
        criteria[element] = {
          label: "",
          formLabel: "",
          data: null,
          formData: null,
        };
      });
      criteria.quickSearchRegistry.label = this.form.inpt_label_registry;
      criteria.quickSearchRegistry.formLabel = "form.inpt_label_registry";
      criteria.quickSearchRegistry.data = this.registry_data;
      criteria.quickSearchRegistry.formData = "registry_data";
      this.saveQuickSearchByName({ name, criteria });
      this.removePaginationByName(name);
      this.$refs[this.tableRef].currentPage = 1;
      this.$refs[this.tableRef].fetch();
    },
    onClearFilter(name) {
      this.resetRegistryId();
      this.resetFilter();
      this.resetPagination();
      this.removeFilterByName(name);
      this.removeQuickSearchByName(name);
      this.removePaginationByName(name);
      this.removeSortByName(name);
      let criteria = this.filter;
      this.saveFilterByName({ name, criteria });
      this.$refs[this.tableRef].sortBy = "book_date";
      this.$refs[this.tableRef].sortDesc = true;
    },
    openQuickSearchRegistry() {
      this.filter.byRegistry.id = null;
      this.$bvModal.show("quickSearchRegistryModal");
    },
    handleRegistryModalInput(value) {
      this.filter.byRegistry.id = value.id;
      this.registry_data = value;
      this.form.inpt_label_registry = this.toInfoData(
        this.registry_data,
        "registryfullname"
      );
      this.$bvModal.hide("quickSearchRegistryModal");
    },
    resetRegistryId() {
      this.registry_data = null;
      this.form.inpt_label_registry = null;
      this.filter.byRegistry.id = null;
    },
    setSectors() {
      const companySectors = this.getSectors().filter(
        (sector) => sector.is_agency === "N"
      );
      const systemSectors = companySectors.filter(
        (sector) => sector.is_system === "Y"
      );
      const notSystemSectors = companySectors.filter(
        (sector) => sector.is_system === "N"
      );
      this.sectors = [...notSystemSectors, ...systemSectors];
    },
    ...mapActions("filters", {
      saveFilterByName: "saveByName",
      removeFilterByName: "removeByName",
    }),
    ...mapActions("quicksearch", {
      saveQuickSearchByName: "saveByName",
      removeQuickSearchByName: "removeByName",
    }),
    ...mapActions("pagination", { removePaginationByName: "removeByName" }),
    ...mapActions("sorts", {
      removeSortByName: "removeByName",
    }),
    ...mapGetters("auth", {
      getSalesmen: "salesmen",
      getInsurers: "insurers",
      getPaymentReferences: "payment_references",
      getSectors: "sectors",
    }),
    onSaveFilter(name, criteria) {
      this.saveFilterByName({ name, criteria });
    },
  },
  created() {
    /* */
    const path = "auth/";
    const resources = [];
    if (!this.hasLoadedResource("insurers")) {
      resources.push(
        this.$store
          .dispatch(`${path}insurers`)
          .then(() => {})
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    } else {
      this.companies = this.getInsurers();
    }
    if (!this.hasLoadedResource("payment_references"))
      resources.push(
        this.$store
          .dispatch(`${path}payment_references`)
          .then(() => {})
          .catch((error) => {
            let errMsg = this.$getErrorMessage(error);
            this.$showSnackbar({ preset: "error", text: errMsg });
          })
      );
    if (resources.length) {
      this.getResources(resources)
        .then(() => {
          this.companies = this.getInsurers();
          this.opt_payment_reference = this.getPaymentReferences();
        })
        .catch((error) => {
          console.log("One or more promises crashed... :|");
          this.onFailure(error);
        })
        .finally(() => {
          this.isLoading = false;
        });
    } else {
      this.companies = this.getInsurers();
      this.opt_payment_reference = this.getPaymentReferences();
      this.isLoading = false;
    }
    /* */
  },
  mounted() {
    this.salesmen = this.getSalesmen();
    // this.companies = this.getInsurers();
    // this.payment_references_opt = this.getPaymentReferences();
    this.setSectors();
    // #721: tendina con mov vari non di sistema
    const Repo = RepositoryFactory.get("various_accounting");
    let queryString = "byAttribute[is_system]=N&perPage=0";
    Repo.index(queryString).then((response) => {
      this.various_accounting_not_system_options = response.data.data.map(
        (e) => {
          return { value: e.id, text: e.title };
        }
      );
    });
  },
};
</script>
